import React from "react";
import { useWeb3React } from "@web3-react/core";
import { Trans } from "@lingui/macro";
import useSWR from "swr";

import Footer from "components/Footer/Footer";
import "./Poll.css";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";
import linkIcon from "img/link.svg";
import tailUsdcIcon from "img/tail-usdc.svg";
import tailEthIcon from "img/tail-eth.svg";
import tailBoneIcon from "img/tail-bone.svg";
import tailGoerliIcon from "img/tail-goerli.svg";
import pollBackImage from "img/img_right.svg";
import { getContract } from "config/contracts";
import { useTotalGmxInLiquidity, useTotalFundInLiquidity, useGmxPrice } from "domain/legacy";
import { DEFAULT_DECIMALS, USD_DECIMALS, USDC_DECIMALS, TAIL_DECIMALS, shortenAddress } from "lib/legacy";
import { formatAmount, expandDecimals } from "lib/numbers";
import { contractFetcher } from "lib/contracts";
import { getTokenBySymbol } from "config/tokens";
import Vault from "abis/Vault.json";
import { useChainId } from "lib/chains";
import { getExplorerUrl } from "config/chains";
import { ARBITRUM, AVALANCHE, FANTOM, MUMBAI } from "config/chains";

export default function Poll() {
  const { chainId } = useChainId();
  const blockExplorer = getExplorerUrl(chainId);

  const { active } = useWeb3React();

  let totalGmxInLiquidity;
  let tokenAddress;
  let unit_decimal;
  let tokenName;
  let lpTokenAddress;

  let { arbitrum: gmxReserveInArbitrum, avax: gmxReserveInAvax, fantom: gmxReserveInFantom } = useTotalGmxInLiquidity(chainId);

  let pairIcon;
  if (chainId === ARBITRUM) {
    totalGmxInLiquidity = gmxReserveInArbitrum;

    unit_decimal = DEFAULT_DECIMALS;
    tokenName = "ETH";
    pairIcon = tailEthIcon;
    tokenAddress= getTokenBySymbol(chainId, "WETH").address;

    lpTokenAddress = getContract(chainId, "UniswapGmxEthPool");
  }
  else if (chainId === AVALANCHE) {
    totalGmxInLiquidity = gmxReserveInAvax;

    unit_decimal = DEFAULT_DECIMALS;
    tokenName = "BONE";
    pairIcon = tailBoneIcon;
    tokenAddress= getTokenBySymbol(chainId, "WAVAX").address;

    lpTokenAddress = getContract(chainId, "TraderJoeGmxAvaxPool");
  }
  else if (chainId === FANTOM) {
    totalGmxInLiquidity = gmxReserveInFantom;

    unit_decimal = USDC_DECIMALS;
    tokenName = "USDC";
    pairIcon = tailUsdcIcon;
    tokenAddress= getTokenBySymbol(chainId, "USDC").address;

    lpTokenAddress = getContract(chainId, "UniswapGmxUsdcPool");
  }
  else if (chainId === MUMBAI) {
    totalGmxInLiquidity = gmxReserveInFantom;

    unit_decimal = DEFAULT_DECIMALS;
    tokenName = "ETH";
    pairIcon = tailGoerliIcon;
    tokenAddress= getTokenBySymbol(chainId, "ETH").address;

    lpTokenAddress = getContract(250, "UniswapGmxUsdcPool");
  }
  const shortLpTokenAddress = shortenAddress(lpTokenAddress, 13);
  const lpTokenUrl = blockExplorer + "address/" + lpTokenAddress;
  
  const timeLockAddress = getContract(chainId, "TimeLock");
  const shortTimeLockAddress = shortenAddress(timeLockAddress, 13);
  const timeLockUrl = blockExplorer + "address/" + timeLockAddress;
  
  const liquidityLockAddress = getContract(chainId, "LiquidityLock");
  const shortLiquidityLockAddress = shortenAddress(liquidityLockAddress, 13);
  const liquidityLockUrl = blockExplorer + "address/" + liquidityLockAddress;

  const { gmxPrice } = useGmxPrice(
    chainId,
    undefined,
    active
  );

  let gmxLiquidityCap
  if (gmxPrice && totalGmxInLiquidity)
    gmxLiquidityCap = gmxPrice.mul(totalGmxInLiquidity).div(expandDecimals(1, TAIL_DECIMALS));


  // Get usdc reserved in the pool
  let { result: tokenReserve } = useTotalFundInLiquidity(chainId);

  const vaultAddress = getContract(chainId, "Vault");

  const { data: tokenPrice } = useSWR(
    [`StakeV2:usdcPrice`, chainId, vaultAddress, "getMinPrice", tokenAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  let tokenLiquidityCap;
  if (tokenPrice && tokenReserve)
    tokenLiquidityCap = tokenPrice.mul(tokenReserve).div(expandDecimals(1, unit_decimal));

  let totalLiquidityCap;
  if (gmxLiquidityCap && tokenLiquidityCap)
    totalLiquidityCap = gmxLiquidityCap.add(tokenLiquidityCap);

  return (
    <SEO title={getPageTitle("Proof of Locked Liquidity")}>
      <div className="page-layout Poll-layout">
        <div className="Poll-sublayout">
          <div className="Poll-container">
            <div className="title">
              <Trans>Proof of Locked Liquidity</Trans>
            </div>
            <div>
              <Trans>Liquidity is locked by renouncing the ownership of liquidity pool (LP) tokens for a fixed time period, by sending them to a time-lock smart contract.</Trans>
            </div>
            <div className="link-wrapper">
              <div>
                <div className="label">LP token</div>
                <div className="info">{shortLpTokenAddress}</div>
                <a href={lpTokenUrl} target="_blank" rel="noreferrer">
                  <img src={linkIcon} alt="link" />
                </a>
              </div>
              <div>
                <div className="label">Time lock</div>
                <div className="info"> {shortTimeLockAddress}</div>
                <a href={timeLockUrl} target="_blank" rel="noreferrer">
                  <img src={linkIcon} alt="link" />
                </a>
              </div>
              <div>
                <div className="label">Liquidity lock</div>
                <div className="info"> {shortLiquidityLockAddress}</div>
                <a href={liquidityLockUrl} target="_blank" rel="noreferrer">
                  <img src={linkIcon} alt="link" />
                </a>
              </div>
            </div>
            <div className="liquididy-wrap">
              <div className="liquidity-boxes">
                <div className="liquidity-box">
                  <div className="liquidity-label">Total liquidity locked</div>
                  <div className="liquidity-box-amount txt-green ">${formatAmount(totalLiquidityCap, USD_DECIMALS, 0, true)}</div>
                </div>
                <div className="liquidity-box">
                  <div className="liquidity-label">Lock until</div>
                  <div className="liquidity-box-amount">Jul 01, 2023</div>
                </div>
              </div>
              <div className="liquidity-pool-wrap">
                <div>
                  <div className="liquidity-label">Pair</div>
                  <div className="liquidity-pool-amount"> 
                    <img src={pairIcon} alt="img" /> TAIL / {tokenName}
                  </div>
                </div>
                <div>
                  <div className="liquidity-label">Pooled TAIL</div>
                  <div className="liquidity-pool-amount"> {formatAmount(totalGmxInLiquidity, TAIL_DECIMALS, 0, true)}
                    <span className="to-usd">${formatAmount(gmxLiquidityCap, USD_DECIMALS, 0, true)}</span>
                  </div>
                </div>
                <div>
                  <div className="liquidity-label">Pooled {tokenName}</div>
                  <div className="liquidity-pool-amount"> {formatAmount(tokenReserve, unit_decimal, 0, true)}
                    <span className="to-usd">${formatAmount(tokenLiquidityCap, USD_DECIMALS, 0, true)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Poll-back">
            <img src={pollBackImage} alt="bg" />
          </div> 
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
